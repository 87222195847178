<template>
  <AbstractFilter
    :on-filter="filterInfoboxes"
    :on-filter-reset="resetFilter"
    create-new-route-name="infobox_new"
    filter-panel-class="filter-panel-infobox"
  >
    <Input
      v-model="filter.title"
      id="filter_title"
      :label="$t('infobox.filter.title')"
    />
    <Input
      v-model="filter.heading"
      id="filter_heading"
      :label="$t('infobox.filter.heading')"
    />
    <Input
      v-model="filter.bodyText"
      id="filter_bodyText"
      :label="$t('infobox.filter.bodytext')"
    />
    <Input
      v-model="filter.id"
      id="filter_id"
      :label="$t('infobox.filter.id')"
    />
  </AbstractFilter>
</template>

<script>

import Input from '@/components/form/inputs/Input'
import InfoboxFilterModel from '@/model/InfoboxFilterModel'
import AbstractFilter from '@/components/filter/AbstractFilter'

export default {
  name: 'InfoboxFilter',
  components: {
    AbstractFilter,
    Input
  },
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(InfoboxFilterModel)
    }
  },
  methods: {
    filterInfoboxes () {
      this.$store.commit('infobox/setPage', 1)
      this.$store.commit('infobox/setFilter', this.filter)
      this.$store.dispatch('infobox/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(InfoboxFilterModel)
      this.$store.commit('infobox/setFilter', this.filter)
      this.$store.dispatch('infobox/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['infobox/filter']
  }
}
</script>

<style lang="scss">
  .filter-panel-infobox {
    @include bp(7) {
      grid-template-columns: 1fr 1fr;
    }
    @include bp(12) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
</style>

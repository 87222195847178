<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="articleHasId"
    :click-handler-for-edit-btn="showModal"
  >
    <button v-if="!articleHasId" class="related-article-block__btn-empty" @click="showModal">
      {{ $t('nmhEditor.blocks.relatedArticleBlock.empty') }}
    </button>
    <span v-if="articleHasId" class="related-article-block__text">{{ articleDataTitle }}</span>
    <RelatedArticlesModal
      v-if="theModalIsVisible"
      :body-blocks="nmhEditorStoreData.blocks"
      :selectedData="selectedData"
      :multiple="true"
      @close="hideModal"
      @set-data="setRelatedArticle"
    />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import coreApi from '@/api/core'
import RelatedArticlesModal from '@/components/article/ArticleRelatedModal'
import { createBlock } from '@/services/nmhEditor/NmhEditorService'
import { mapState } from 'vuex'

export default {
  name: 'RelatedArticleBlock',
  components: {
    Block,
    RelatedArticlesModal
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      theModalIsVisible: false,
      selectedData: [],
      articleId: null,
      articleUuid: null,
      articleDataTitle: null
    }
  },
  computed: {
    ...mapState({
      nmhEditorStoreData: state => state.nmhEditorStore.data
    }),
    articleHasId () {
      return this.properties.articleUuid.length > 0
    }
  },
  methods: {
    showModal () {
      this.theModalIsVisible = true
    },
    hideModal () {
      this.theModalIsVisible = false
    },
    loadArticleData (id) {
      if (this.properties.articleUuid.length) {
        const url = '/article?view=minimal&filter_eq[setting.status]=published&filter_in[documentId]=' + id
        return coreApi().get(url)
          .then(response => {
            this.articleDataTitle = response.data.data[0].field.title
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    setRelatedArticle (data) {
      data.forEach((item, index) => {
        this.articleId = item.id
        this.articleUuid = item.entityUuid.toString()
        this.loadArticleData(this.articleUuid)
        const relatedArticleBlock = createBlock('relatedArticleBlock')
        relatedArticleBlock.properties = {
          ...relatedArticleBlock.properties,
          articleId: this.articleId,
          articleUuid: this.articleUuid
        }
        this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', (this.index) + index)
        this.$store.commit('nmhEditorStore/SET_BLOCKS', { setNewBlock: relatedArticleBlock })
      })
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        deleteSingleBlockProgrammatically: this.index
      })
    }
  },
  mounted () {
    if (this.articleHasId) {
      this.loadArticleData(this.properties.articleUuid)
    }
  }
}
</script>

<style scoped lang="scss">
  .related-article-block {
    &__btn-empty {
      @include font(500 13px "Roboto");
      @include margin(_ auto);
      @include padding(8px 14px);
      @include radius(6px);
      cursor: pointer;
      display: block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__text {
      @include font(400 15px "Roboto");
      @include relative(_);
      @include padding(_ _ _ 15px);
      display: flex;
      align-items: center;
      &::before {
        @include absolute(5px _ _ 0);
        @include size(8px);
        @include radius(50%);
        background: #465674;
        content: '';
      }
    }
  }
</style>

<template>
  <app-article-view>
    <div slot="actions" class="col-lg-12">
      <app-article-create-modal
        titleMessage="topicOffer.transfer_article"
        v-if="showModal"
        :articleSourceType="TRANSFER_ARTICLE"
        :data="article"
        @close="toggleTransferModal"
      >
      </app-article-create-modal>
      <button
        type="button"
        class="btn btn-green float-right"

        @click="toggleTransferModal"
      >
        {{ $t('topicOffer.transfer') }}
      </button>
    </div>
    <template slot="history">
      <h3 class="article-edit-header">{{ $t("topicOffer.transfered_articles") }}</h3>
      <router-link
        v-for="article in transferedArticles" :key="article.id"
        tag="a"
        :to="{name: 'articleByDocumentId', params: {id: article.documentId}}"
        target="_blank"
      >
        {{ article.documentId }}
      </router-link>
    </template>
  </app-article-view>
</template>

<script>
import { TRANSFER_ARTICLE } from '@/model/ValueObject/ArticleCreateTypes'
import ArticleCreateModal from '../article/ArticleCreateModal'
import ArticleView from '../../views/article/ArticleView'

export default {
  name: 'TopicOffer',
  extends: ArticleView,
  components: {
    appArticleCreateModal: ArticleCreateModal,
    appArticleView: ArticleView
  },
  data () {
    return {
      TRANSFER_ARTICLE,
      showModal: false,
      transferedArticles: []
    }
  },
  methods: {
    toggleTransferModal () {
      this.showModal = !this.showModal
    }
  },
  watch: {
    article (article) {
      this.$store.dispatch('article/fetchTransferedArticles', article.id).then((articles) => {
        this.transferedArticles = articles
      })
    }
  }
}
</script>

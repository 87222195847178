<template>
  <app-module-view :disableBreadcrumb="disableBreadcrumb">
    <template slot="buttons">
      <app-button-create
        :route-name="`video_new${getVideoRoute}`"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-preloader v-if="callingAPI" />
      <app-video-filter
        :show-create-button="false"
        simple-table-list
      />
      <div v-if="autoRefresh">
        <app-autoloader
          v-if="videos.length > 0"
          :callback="getVideos"
          :height="2"
          :interval="60"
        >
        </app-autoloader>
      </div>
      <div class="card">
        <div class="card-body">
          <div v-if="callingAPI === false && videos.length == 0" class="m-t-10">
            <p class="alert alert-info">
              {{ $t('result_not_found') }}
            </p>
          </div>
          <div class="table-responsive" v-if="videos.length > 0">
            <table id="list-table" class="table table-striped">
              <thead>
              <tr>
                <th v-if="showSelectButton">

                </th>
                <th class="hidden-sm-down">
                  {{ $t("video.list.id") }}
                </th>
                <th>
                  {{ $t("video.list.type") }}
                </th>
                <th>
                  {{ $t("video.list.preview_image") }}
                </th>
                <th>
                  {{ $t("video.list.title") }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("video.list.default_site") }}
                </th>
                <th width="200">
                  {{ $t("video.list.status") }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("video.list.used_in_article") }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("video.list.video_duration") }}
                </th>
                <th class="hidden-sm-down">
                  {{ $t("video.list.created_at") }}
                </th>
                <th width="180" v-if="disableActions === false">
                  {{ $t("actions") }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(video, index) in videos" :key="`item-${index}`">
                <td v-if="showSelectButton">
                  <button
                    class="btn btn-default"
                    :title="$t('buttons.select')"
                    @click="selectRecord(video)"
                  >
                    {{ $t('buttons.select') }}
                  </button>
                </td>
                <td class="hidden-sm-down">
                  {{ video.id }}
                </td>
                <td>
                  <app-video-type-icon :video="video"></app-video-type-icon>
                </td>
                <td>
                  <app-media
                    v-if="video.previewImage"
                    :media="video.expanded.previewImage"
                    :width="160"
                    :height="90"
                  >
                  </app-media>
                  <app-youtube-thumbnail
                    v-if="video.youtubeId"
                    :youtubeId="video.youtubeId"
                    :title="video.meta.title"
                  >
                  </app-youtube-thumbnail>
                </td>
                <td>
                  {{ video.meta.title }}<br>
                  <span class="label label-warning" v-if="video.setting.exclusive">
                                        {{ $t("video.setting_exclusive") }}
                                    </span>
                  <span class="label label-info" v-if="video.setting.productPlacement">
                                        {{ $t("video.setting_product_placement") }}
                                    </span>
                  <span class="label label-danger" v-if="video.setting.forAdults">
                                        18+
                                    </span>
                  <span class="label label-info" v-if="video.setting.noAds">
                                        {{ $t("video.setting_no_ads") }}
                                    </span>
                </td>
                <td class="hidden-sm-down">
                  {{ video.expanded.defaultSite.title }}
                </td>
                <td>
                  <app-video-status
                    :video="video"
                  >
                  </app-video-status>
                  <br>
                  <small v-if="video.statusMessage">{{ video.statusMessage }}</small>
                </td>
                <td class="hidden-sm-down">
                  {{ video.articles.length }}
                </td>
                <td class="hidden-sm-down">
                  {{ video.videoDuration | formatMilliseconds }}
                </td>
                <td class="hidden-sm-down">
                  {{ video.createdAt | prettyDateTime }}<br>
                  {{ userNameById(video.createdBy) }}
                </td>
                <td v-if="disableActions === false">
                  <router-link
                    tag="a"
                    class="btn btn-default btn-sm"
                    :to="{ name: `video_detail${getVideoRoute}`, params: { id: video.id }}"
                    data-test="video_list_btn_detail"
                  >
                    <i class="fa fa-info"></i>
                  </router-link>
                  <app-button-copy-to-clipboard
                    class="btn btn-default btn-sm"
                    data-test="video_list_btn_copy"
                    :data="video.id | toString"
                    :notify-message="$t('notify.id_was_copied')"
                  >
                  </app-button-copy-to-clipboard>
                  <router-link
                    tag="a"
                    class="btn btn-default btn-sm"
                    :to="{ name: `video_edit${video_detail}`, params: { id: video.id }}"
                    data-test="video_list_btn_edit"
                  >
                    <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <p class="m-t-20">{{ $t('found') }}: {{ totalCount }}</p>
            </div>
            <div class="col-lg-6">
              <div id="editable-datatable_wrapper" class="dataTables_wrapper" v-if="videos.length > 0">
                <div class="dataTables_paginate paging_simple_numbers" id="editable-datatable_paginate">
                  <app-paginate
                    v-model="currentPage"
                    :page-count="pageCount"
                    :page-range="5"
                    :margin-pages="2"
                    :click-handler="setPageAndGetRecords"
                    :prev-text="$t('page_prev')"
                    :next-text="$t('page_next')"
                    :container-class="'pagination'"
                    :prev-class="'paginate_button previous'"
                    :next-class="'paginate_button next'"
                    :page-class="'paginate_button'">
                  </app-paginate>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import { mapState } from 'vuex'
import config from '../../config'
import Preloader from '@/components/preloader/Preloader'
import VideoFilter from './VideoFilter'
import Paginate from 'vuejs-paginate'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import VideoStatus from './VideoStatus'
import VideoTypeIcon from './VideoTypeIcon'
import Media from '@/components/shared/Media'
import YoutubeThumbnail from '@/components/shared/YoutubeThumbnail'
import UserService from '../../services/user/UserService'
import Autoloader from '@/components/Autoloader'
import ButtonCreate from '@/components/shared/ButtonCreate'

export default {
  name: 'VideoSimpleTableList',
  props: {
    showSelectButton: {
      type: Boolean,
      default: false
    },
    disableActions: {
      type: Boolean,
      default: false
    },
    disableBreadcrumb: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      autoRefresh: true,
      dataLoaded: false,
      currentPage: 1
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    getVideoRoute () {
      if (this.$route && this.$route.name.includes('old')) {
        return '_old'
      } else {
        return ''
      }
    },
    videos () {
      return this.$store.getters['video/list']
    },
    totalCount () {
      return this.$store.getters['video/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['video/totalCount'] / config.defaults.list.limit)
    },
    page () {
      return this.$store.getters['video/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appPaginate: Paginate,
    appVideoFilter: VideoFilter,
    appButtonCopyToClipboard: BtnCopyToClipboard,
    appVideoStatus: VideoStatus,
    appVideoTypeIcon: VideoTypeIcon,
    appMedia: Media,
    appYoutubeThumbnail: YoutubeThumbnail,
    appAutoloader: Autoloader,
    appButtonCreate: ButtonCreate
  },
  methods: {
    getVideos () {
      this.$store.dispatch('video/fetch')
        .finally(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('video/setPage', page)
      this.getVideos()
    },
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    selectRecord (record) {
      this.$emit('select-record', [record])
    }
  },
  mounted () {
    this.getVideos()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>

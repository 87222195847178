<template>
  <app-modal
    :title="$t('article.modal.infobox_create_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <div class="row m-b-10">
        <div class="col-lg-12 text-right">
          <button class="btn btn-secondary active" @click="showInfoboxCreateModal">
            {{ $t('article.modal.infobox_create') }}
          </button>
          <button class="btn btn-secondary" @click="showInfoboxListModal">
            {{ $t('article.modal.infobox_select') }}
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <input type="hidden" v-model="infobox.id">
          <app-input
            v-model="infobox.title"
            @blur="$v.infobox.title.$touch()"
            :error="$v.infobox.title.$error"
            id="infobox_title"
            :label="$t('article.modal.infobox_title')">
          </app-input>
          <app-input
            v-model="infobox.heading"
            @blur="$v.infobox.heading.$touch()"
            :error="$v.infobox.heading.$error"
            id="infobox_heading"
            :label="$t('article.modal.infobox_heading')">
          </app-input>
          <app-textarea
            v-model="infobox.bodyText"
            id="bodyText"
            :label="$t('article.modal.infobox_bodytext')"
            :rows="10"
          >
          </app-textarea>
          <div class="form-group">
            <label>{{ $t('infobox.image') }}</label><br>
            <app-media-select-button
              @set-media="setImage"
            >
            </app-media-select-button>
            <app-media-upload-button
              @set-media="setImage"
            >
            </app-media-upload-button>
          </div>
          <app-media-editable
            v-if="infoboxImage"
            :media="infoboxImage"
            :width="360"
            :height="202"
            @remove-media="removeImage"
          >
          </app-media-editable>
        </div>
      </div>
    </template>

    <!-- Sub header -->
    <template slot="sub-header">
      <div class="row text-center">
        <div class="col-md-12">
          <div class="input-group-btn">
            <button @click="create" type="button" class="btn btn-green">{{ $t('article.modal.insert') }}</button>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import InfoboxModel from '@/model/InfoboxModel'
import NotifyService from '@/services/NotifyService'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import Input from '../../form/inputs/Input'
import Textarea from '../../form/Textarea'
import Modal from '../../shared/Modal'
import MediaSelectButton from '../../shared/MediaSelectButton'
import MediaUploadButton from '../../shared/MediaUploadButton'
import MediaEditable from '../../shared/MediaEditable'

export default {
  name: 'InfoboxCreateModal',
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      infobox: this._.cloneDeep(InfoboxModel)
    }
  },
  validations: {
    infobox: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      heading: {
        required,
        minLength: minLength(3),
        maxLength: maxLength
      }
    }
  },
  props: {
    selectedData: {
      type: String,
      default: ''
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appMediaSelectButton: MediaSelectButton,
    appMediaUploadButton: MediaUploadButton,
    appMediaEditable: MediaEditable
  },
  computed: {
    infoboxImage () {
      return this.$store.getters['infobox/infoboxImage']
    }
  },
  methods: {
    close () {
      this.clearData()
      this.$emit('close')
    },
    clearData () {
      this.value = ''
    },
    showInfoboxCreateModal () {
      this.$emit('show-infobox-create-modal')
    },
    showInfoboxListModal () {
      this.$emit('show-infobox-list-modal')
    },
    async create () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('article.notify.please_fill_all_required_fields'))
        return
      }
      this.infobox.image = null
      if (this.$store.getters['infobox/infoboxImage']) {
        this.infobox.image = this.$store.getters['infobox/infoboxImage'].id
      }
      this.$store.dispatch('infobox/create', this.infobox)
        .then(() => {
          if (this.$store.getters['infobox/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('article.notify.infobox_created'))
            this.$emit('set-data', this.$store.getters['infobox/detail'])
            this.clearData()
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['infobox/error'])
          }
        })
        .catch(error => console.log(error))
    },
    setImage (medias) {
      medias.forEach(media => {
        this.$store.commit('infobox/storeInfoboxImage', media)
      })
    },
    removeImage () {
      this.$store.commit('infobox/storeInfoboxImage', null)
    }
  },
  created () {
    this.removeImage()
  }
}
</script>

<style>
</style>

import _ from 'lodash'
import store from '../../store/index'
const IS_VLM = store.getters.vlm
const COMPANY = store.getters.company

const transitionErrors = {
  field: {
    alternativeTitle: false,
    title: false,
    subTitle: false,
    shortTitle: false,
    firstParagraph: false,
    bodyText: false,
    video: false
  },
  expanded: {
    heroImage: false
  },
  external: {
    url: false
  },
  safetyTopics: false,
  categories: false,
  sharedUsers: false,
  authors: false,
  natureOfTheArticle: false,
  sourceType: false,
  custom: {
    paidContentEmbedMissing: false,
    paidContentNotChecked: false,
    paidContentEmbedMultiple: false,
    pageTitlesPageBreaksCount: false
  },
  abTestVariantTitle: false
}

const trendSeoTitleSuffix = ' | TREND'

const getFieldsCharsLimit = (article, toReady = false) => {
  return {
    nmh: {
      title: {
        min: article.type === 'disease' || article.type === 'encyclopedy' ? 3 : (toReady ? 50 : 1),
        max: 95
      },
      seoTitle: {
        min: 50,
        max: 60
      },
      titleForUrl: {
        min: 3,
        max: 95
      },
      perex: {
        min: 9,
        warningMax: 300,
        max: 1000
      }
    },
    vlm: {
      alternativeTitle: {
        min: article.setting.prArticle ? 15 : 1,
        max: article.setting.prArticle ? 95 : 105
      },
      title: {
        min: article.setting.prArticle ? 15 : 1,
        max: article.setting.prArticle ? 95 : 105
      },
      seoTitle: {
        min: article.setting.prArticle ? 15 : 50,
        max: article.setting.prArticle ? 95 : 60
      },
      titleForUrl: {
        min: article.setting.prArticle ? 15 : 1,
        max: article.setting.prArticle ? 95 : 105
      },
      perex: {
        min: 9,
        warningMax: 300,
        max: 1000
      }
    }
  }
}
const hasErrors = (article, toReady = false) => {
  const errors = _.cloneDeep(transitionErrors)
  let hasError = false
  const { min: minTitleLength, max: maxTitleLength } = getFieldsCharsLimit(article, toReady)[COMPANY].title

  if (!article.expanded.heroImage && article.type !== 'disease') {
    errors.expanded.heroImage = true
    hasError = true
  }

  if (IS_VLM && toReady && !(article.geneeaTags?.length > 0)) {
    errors.geneeaTags = true
    hasError = true
  }

  if (article.field.title.length < minTitleLength || article.field.title.length > maxTitleLength) {
    errors.field.title = true
    hasError = true
  }

  const { min: minAlternativeTitleLength, max: maxAlternativeTitleLength } = getFieldsCharsLimit(article).vlm.alternativeTitle

  if (
    IS_VLM &&
    store.state.article.alternativeTitleCheckbox &&
    (
      article.field.alternativeTitle.length < minAlternativeTitleLength ||
      article.field.alternativeTitle.length > maxAlternativeTitleLength
    )
  ) {
    errors.field.alternativeTitle = true
    hasError = true
  }

  if (article.setting.abTested && article.abTestVariants instanceof Array) {
    article.abTestVariants.forEach(variant => {
      if (variant.title.length < minTitleLength || variant.title.length > maxTitleLength) {
        errors.abTestVariantTitle = true
        hasError = true
      }
    })
  }

  const { perex } = getFieldsCharsLimit(article)[COMPANY]
  if (article.type !== 'disease' && (article.field.firstParagraph.length < perex.min || article.field.firstParagraph.length > perex.max)) {
    errors.field.firstParagraph = true
    hasError = true
  }

  if (article.sharedUsers.length === 0) {
    errors.sharedUsers = true
    hasError = true
  }

  if (IS_VLM && article.type !== 'external' && article.authors.length === 0) {
    errors.authors = true
    hasError = true
  }

  if (article.type !== 'external') {
    if (article.field.shortTitle.length < minTitleLength || article.field.shortTitle.length > maxTitleLength) {
      errors.field.shortTitle = true
      hasError = true
    }
    if (article.safetyTopics.length === 0) {
      errors.safetyTopics = true
      hasError = true
    }
    if (article.categories.length === 0) {
      errors.categories = true
      hasError = true
    }
    // Nature of the article
    if (article.flag.native === false &&
      article.setting.prArticle === false &&
      article.setting.editorialArticle === false
    ) {
      errors.natureOfTheArticle = true
      hasError = true
    }
    // Source type
    if (article.setting.internalAuthor === false &&
      article.setting.agencyArticle === false &&
      article.setting.copiedFromPrint === false &&
      article.setting.externalAuthor === false &&
      article.setting.transferedArticle === false
    ) {
      errors.sourceType = true
      hasError = true
    }
  }

  if (
    article.type !== 'external' &&
    article.type !== 'quiz' &&
    article.type !== 'recipe' &&
    article.type !== 'video' &&
    article.field.bodyText.length < 17 &&
    article.bodyBlocks.length < 1
  ) {
    errors.field.bodyText = true
    hasError = true
  }

  if (article.type === 'external' && article.external.url === '') {
    errors.external.url = true
    hasError = true
  }

  if (article.type === 'video' && article.field.video === '') {
    errors.field.video = true
    hasError = true
  }

  // check if body contains paidContentBreak correctly
  const paidContentBreakCount = (article.field.bodyText.match(/###{PAIDCONTENTBREAK}###/g) || []).length
  const paidContentBreakCountNewEditor = article.bodyBlocks.filter(item => item.type === 'paidContentBreakBlock')
  if (article.setting.paidContent === true && paidContentBreakCount === 0 && paidContentBreakCountNewEditor.length === 0) {
    errors.custom.paidContentEmbedMissing = true
    hasError = true
  } else if (article.setting.paidContent === false && paidContentBreakCount > 0 && paidContentBreakCountNewEditor.length > 0) {
    errors.custom.paidContentNotChecked = true
    hasError = true
  } else if (paidContentBreakCount > 1 || paidContentBreakCountNewEditor.length > 1) {
    errors.custom.paidContentEmbedMultiple = true
    hasError = true
  }

  // check if page titles count match page breaks count +1
  // if (article.field.pageTitles.length > 0) {
  //   const pageBreaksCount = (article.field.bodyText.match(/###{PAGEBREAK}###/g) || []).length
  //   if (pageBreaksCount + 1 !== article.field.pageTitles.length) {
  //     hasError = true
  //     errors.custom.pageTitlesPageBreaksCount = true
  //   }
  // }

  // seo(sub)title validation
  if (isSeoTitleInvalid(article)) {
    hasError = true
    errors.field.subTitle = true
  }

  if (hasError) {
    return errors
  }
  return false
}

const isSeoTitleInvalid = (article) => {
  let seoTitleLength = article.field.subTitle.length
  if (article.expanded && ['trend'].includes(article.expanded.site.name)) {
    seoTitleLength += trendSeoTitleSuffix.length
  }
  const { min, max } = getFieldsCharsLimit(article)[COMPANY].seoTitle
  return (seoTitleLength < min || seoTitleLength > max)
}

export default {
  transitionErrors,
  hasErrors,
  getFieldsCharsLimit,
  trendSeoTitleSuffix
}

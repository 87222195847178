<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="quizHasId"
    :click-handler-for-edit-btn="showQuizModal"
  >
    <button v-if="!quizHasId" class="quiz-block__btn-empty" @click="showQuizModal">
      {{ $t('nmhEditor.blocks.quizBlock.empty') }}
    </button>
    <div v-if="quizHasId" class="quiz-block__not-empty-wrapper">
      <span class="quiz-block__icon">Q</span>
      <span class="quiz-block__text">
        {{ $t('nmhEditor.blocks.quizBlock.notEmpty') }} - {{ quizData }}
      </span>
    </div>
    <QuizModal
      v-if="quizModal"
      @set-data="setQuiz"
      @close="closeQuizModal"
    />
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import coreApi from '@/api/core'
import QuizModal from '@/components/article/ArticleQuizModal'

export default {
  name: 'QuizBlock',
  components: {
    Block,
    QuizModal
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      selectedData: null,
      quizId: null,
      quizUuid: null,
      quizData: null,
      quizModal: false
    }
  },
  computed: {
    quizHasId () {
      return this.properties.quizId !== ''
    }
  },
  methods: {
    showQuizModal () {
      this.quizModal = true
    },
    closeQuizModal () {
      this.quizModal = false
    },
    loadQuizData (id) {
      if (!isNaN(this.properties.quizId)) {
        const url = '/quiz/' + id
        return coreApi().get(url)
          .then(response => {
            this.quizData = response.data.title
          })
          .catch(error => {
            console.error(error)
          })
      }
    },
    setQuiz (data) {
      this.quizId = data.selectedData.id
      this.quizUuid = data.selectedData.entityUuid
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'quizId',
        data: this.quizId
      })
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'quizUuid',
        data: this.quizUuid
      })
      this.loadQuizData(this.quizId)
    }
  },
  mounted () {
    if (this.quizHasId) {
      this.loadQuizData(this.properties.quizId)
    }
  }
}
</script>

<style scoped lang="scss">
  .quiz-block {
    &__not-empty-wrapper {
      @include grid-gap(9px);
      display: grid;
      grid-template-columns: min-content auto;
    }
    &__btn-empty {
      @include font(500 13px "Roboto");
      @include margin(_ auto);
      @include padding(8px 14px 8px);
      @include radius(6px);
      cursor: pointer;
      display: block;
      background: #6599fe;
      color: #fff;
      border: none;
      transition: background 200ms;
      &:hover {
        background: darken(#6599fe, 10%);
      }
    }
    &__text {
      @include font(400 18px "Roboto");
      @include margin(0);
      @include relative(-1px _ _);
      color: #465674;
    }
    &__icon {
      @include font(400 12px "Roboto");
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-content: center;
      background: #6599fe;
      color: #fff;
      padding: .05rem;
    }
  }
</style>

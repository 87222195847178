<template>
  <app-modal
    :title="$t('hp.create_modal_header')"
    @close="close"
  >
    <!-- Body -->
    <template slot="body">
      <app-video-list
        show-select-button
        disable-actions
        disable-breadcrumb
        @select-record="selectVideo"
      >
      </app-video-list>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/shared/Modal'
import VideoList from './VideoSimpleTableList'
import VideoFilter from '../../model/VideoFilter'

export default {
  name: 'ContentBlockItemNew',
  props: {
    selectedData: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dataLoaded: false,
      isLoading: false,
      filter: this._.cloneDeep(VideoFilter),
      selectedVideo: null
    }
  },
  components: {
    appModal: Modal,
    appVideoList: VideoList
  },
  methods: {
    close () {
      this.$emit('close')
    },
    selectVideo (video) {
      if (this.multiple === false) {
        this.selectedVideo = null
      }
      this.selectedVideo = video[0]
      this.addVideo()
    },
    addVideo () {
      this.$emit('set-data', this.selectedVideo)
      this.$emit('close')
    }
  },
  created () {
    if (this.selectedData.length > 1) {
      this.filter.videoId = this.selectedData
    }
    this.$store.commit('video/setPage', 1)
    this.$store.commit('video/setFilter', this.filter)
  }
}
</script>

<template>
  <Modal
    :title="$t('article.modal.related_articles_header')"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        type="button"
        class="btn btn-info text-right mr-3"
        @click="SelectGroup"
      >
        {{ $t('article.modal.insert_all_ai_generated_related_articles') }}
      </button>
      <button
        @click="addRelatedArticles"
        type="button"
        class="btn btn-green text-right"
        data-dismiss="modal"
        data-test="related_article_btn_select"
        :disabled="selectedArticles.length === 0"
      >
        <i class="fa fa-check"></i> {{ $t('article.modal.select') }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <ArticleListSimple
        articleListType="relatedArticles"
        :multiple="multiple"
        :toggleSelectGroup="toggleSelectGroup"
        @close="close"
        @set-data="addRelatedArticles"
        @select-record="selectArticle"
        class="article-related-modal__list-simple"
      />
    </template>
  </Modal>
</template>

<script>
import Modal from '../shared/Modal'
import ArticleListSimple from './ArticleListSimple'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RelatedArticlesModal',
  components: {
    Modal,
    ArticleListSimple
  },
  props: {
    bodyBlocks: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataLoaded: false,
      selectedArticles: [],
      toggleSelectGroup: {
        ids: [],
        markAll: false
      }
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapGetters('aiStore', ['relatedArticles', 'recommendedIds']),
    ...mapGetters('article', ['fullArticle']),
    aiFeaturesUsagePayload () {
      const payload = {
        userId: this.fullArticle.createdBy,
        siteId: this.fullArticle.site,
        articleId: this.fullArticle.id,
        articleDocumentId: this.fullArticle.documentId,
        aiFeatureType: 'related_articles'
      }
      return payload
    }
  },
  methods: {
    ...mapActions('aiStore', ['fetchRelatedArticles', 'logAiFeaturesUsage']),
    selectArticle (articles) {
      this.selectedArticles = articles
    },
    SelectGroup () {
      this.toggleSelectGroup.ids = this.relatedArticles.map(r => r.id)
      this.toggleSelectGroup.markAll = !this.toggleSelectGroup.markAll
    },
    addRelatedArticles () {
      if (this.selectedArticles.some(article => article.aiRelated)) {
        this.logAiFeaturesUsage(this.aiFeaturesUsagePayload)
          .then(() => {
            this.$emit('articleAiSupported')
            this.$emit('set-data', this.selectedArticles)
            this.$emit('close')
          })
          .catch(error => {
            console.error('Logging AI Features Usage failed:', error)
          })
      } else {
        this.$emit('set-data', this.selectedArticles)
        this.$emit('close')
      }
    },
    combineTextFromBlocks () {
      const combinedText = this.bodyBlocks
        .filter(block => block.properties && block.properties.text)
        .map(block => block.properties.text)
        .join(' ')
      this.fetchRelatedArticles({ bodyText: combinedText, articleId: this.fullArticle.id })
    },
    close () {
      this.$emit('close')
    }
  },
  mounted () {
    if (!this.vlm) {
      this.combineTextFromBlocks()
    }
  }
}
</script>

<style lang="scss">
  .article-related-modal {
    &__list-simple {
      .btn-article-create {
        display: none;
      }
    }
  }
</style>

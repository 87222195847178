<template>
    <span>
       <i
         class="fab fa-youtube"
         v-if="video.type === 'youtube'"
         title="Youtube"
       >
       </i>
        <i
          class="fas fa-video"
          v-if="video.type === 'ooyala'"
          title="Ooyala"
        >
        </i>
    </span>
</template>

<script>
export default {
  name: 'VideoTypeIcon',
  props: {
    video: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
  .fa-youtube {
    color: #ff0000;
  }
</style>

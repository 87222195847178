<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
  >
    <div class="link-to-gallery-block">
      <label class="link-to-gallery-block__area link-to-gallery-block__area--page">
      <span class="link-to-gallery-block__desc">
        {{ $t('nmhEditor.blocks.linkToGalleryBlock.page') }}
      </span>
        <input
          class="link-to-gallery-block__input"
          type="number"
          min="1"
          v-model.trim="linkToGalleryPage"
          :disabled="disabled"
        >
      </label>
      <label class="link-to-gallery-block__area link-to-gallery-block__area--text">
      <span class="link-to-gallery-block__desc">
        {{ $t('nmhEditor.blocks.linkToGalleryBlock.text') }}
      </span>
        <input
          class="link-to-gallery-block__input"
          type="text"
          v-model.trim="linkToGalleryText"
          :disabled="disabled"
        >
      </label>
    </div>
  </Block>
</template>

<script>
import Block from '@/components/nmhEditor/blocks/Block'
import { mapState } from 'vuex'
export default {
  name: 'LinkToGalleryBlock',
  components: {
    Block
  },
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled
    }),
    linkToGalleryPage: {
      set (output) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'page',
          data: parseInt(output)
        })
      },
      get () {
        return this.properties.page
      }
    },
    linkToGalleryText: {
      set (output) {
        this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
          index: this.index,
          property: 'text',
          data: output
        })
      },
      get () {
        return this.properties.text
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .link-to-gallery-block {
    @include grid-gap(20px);
    display: grid;
    &__desc {
      @include font(400 14px "Roboto");
      @include margin(_ _ 6px);
      color: #8a96ac;
    }
    &__input {
      @include font(400 14px "Roboto");
      @include radius(6px);
      @include size(100% 40px);
      @include padding(_ 15px);
      display: flex;
      align-items: center;
      border: solid 1px #d1dbe4;
      background: #fdfdfd;
      color: #465674;
    }
  }
</style>

<template>
  <div v-if="vlm" class="display-inline">
    <button
      type="button"
      class="btn btn-success"
      @click="showTransferToExportArticleModal"
    >
      <i class="fas fa-share" />
      {{ $t('article.transfer_to_export.transfer') }}
    </button>
    <ArticleCreateModal
      v-if="transferToExportArticleModal"
      :data="transferToExportArticle"
      title-message="article.transfer_to_export.title"
      confirm-button-text="article.transfer_to_export.transfer"
      confirm-icon="fas fa-share"
      :articleSourceType="TRANSFER_ARTICLE"
      @close="closeTransferToExportArticleModal"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { TRANSFER_ARTICLE } from '@/model/ValueObject/ArticleCreateTypes'
import ArticleCreateModal from '@/components/article/ArticleCreateModal'

export default {
  name: 'ArticleTransferToExportButton',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  components: {
    ArticleCreateModal
  },
  data () {
    return {
      TRANSFER_ARTICLE,
      transferToExportArticleModal: false,
      transferToExportArticle: null
    }
  },
  computed: {
    ...mapGetters(['vlm'])
  },
  methods: {
    showTransferToExportArticleModal () {
      this.transferToExportArticle = this.article
      this.transferToExportArticleModal = true
    },
    closeTransferToExportArticleModal () {
      this.transferToExportArticleModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.display-inline {
  display: inline
}
</style>

<template>
  <Block
    :block-type="blockType"
    :index-of-the-block="index"
    :special-behavior-after-pressing-enter="false"
    :format-toolbar="['htmlIdAttr']"
    :show-edit-btn="imageHasId"
    :click-handler-for-edit-btn="showEditModal"
  >
    <!--  When we wait for the data  -->
    <p v-if="loading">{{ $t('nmhEditor.loading') }}</p>

    <!--  If we don't have any data  -->
    <div v-if="!imageHasId" class="image-block__no-content-wrapper">
      <ButtonMain :click-handler="showSearchModal">
        {{ $t('nmhEditor.blocks.imageBlock.selectPhoto') }}
      </ButtonMain>
      <MediaUploadButton
        :multiple="false"
        show-page-breaks-checkbox
        @add-page-breaks="addPageBreaks"
        @set-media="addMedia"
      >
        {{ $t('nmhEditor.blocks.imageBlock.uploadPhoto') }}
      </MediaUploadButton>
      <ButtonMain v-if="!vlm" :click-handler="showUrlModal">
        {{ $t('nmhEditor.blocks.imageBlock.uploadPhotoFromUrl') }}
      </ButtonMain>
    </div>

    <!--  If we have some data  -->
    <div v-if="imageHasId && !loading" class="image-block__content-wrapper">
      <div class="image-block__inner-wr">
        <ButtonMain v-if="!disabled" :click-handler="deleteImage" class="image-block__delete-photo">
          {{ $t('nmhEditor.blocks.imageBlock.deletePhoto') }}
        </ButtonMain>
        <img loading="lazy" :src="imageData.url" class="image-block__img">
      </div>
      <div>
        <h4 v-if="imageData.imageCaption.length" class="image-block__title">{{ imageData.imageCaption }}</h4>
        <h5 v-if="imageData.imageAttribution.length" class="image-block__source">
          {{ $t('nmhEditor.blocks.imageBlock.author') }}: {{ imageData.imageAttribution }}
        </h5>
      </div>
    </div>
    <DamSearchModal
      v-if="searchModal"
      @close="hideSearhModal"
      :multiple="true"
      @show-media-edit-modal="showEditModalWithMedia"
    />
    <MediaEditModal
      v-if="editModal"
      :selectedMedia="media"
      show-page-breaks-checkbox
      @add-page-breaks="addPageBreaks"
      @add-media="addMedia"
      @close="hideEditModal"
    />
    <MediaUploadModal
      v-if="uploadModal"
      :files="filesFromUrl"
      :count=0
      show-page-breaks-checkbox
      @add-page-breaks="addPageBreaks"
      @close="hideUploadModal"
      @upload="addMedia"
    />
    <MediaUrlModal
      v-if="urlModal"
      @close="hideUrlModal"
      @show-media-upload-modal="showUploadModal"
    />
  </Block>
</template>

<script>
import ButtonMain from '@/components/nmhEditor/btns/BtnMain'
import Block from '@/components/nmhEditor/blocks/Block'
import { createBlock } from '@/services/nmhEditor/NmhEditorService'
import CoreApi from '@/api/core'
import DamApi from '@/api/dam'
import DamSearchModal from '@/components/shared/DamSearchModal'
import MediaEditModal from '@/components/shared/MediaEditModal'
import MediaUploadButton from '@/components/shared/MediaUploadButton'
import MediaUploadModal from '@/components/shared/MediaUploadModal'
import MediaUrlModal from '@/components/shared/MediaUrlModal'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'ImageBlock',
  props: {
    /**
     * Type of block. For example: 'paragraphBlock', 'headingBlock'...
     */
    blockType: {
      type: String,
      required: true
    },
    /**
     * Index of the current block
     */
    index: {
      type: Number,
      required: true
    },
    /**
     * Object properties of the block
     */
    properties: {
      type: Object,
      required: true
    }
  },
  components: {
    ButtonMain,
    Block,
    DamSearchModal,
    MediaEditModal,
    MediaUploadButton,
    MediaUploadModal,
    MediaUrlModal
  },
  data () {
    return {
      loading: false,
      editModal: false,
      filesFromUrl: '',
      pageBreaks: false,
      searchModal: false,
      imageData: {
        url: '',
        imageCaption: '',
        imageAttribution: ''
      },
      media: [],
      uploadModal: false,
      urlModal: false
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    ...mapState({
      disabled: state => state.nmhEditorStore.disabled || state.article.articleEditDisabled
    }),
    imageHasId () {
      const id = this.properties.damMediaId.toString()
      return id.length
    }
  },
  methods: {
    addMedia (data) {
      let index = this.index
      const addBlock = block => {
        this.$store.commit('nmhEditorStore/SET_POSITION_OF_THE_NEW_BLOCK', index)
        this.$store.commit('nmhEditorStore/SET_BLOCKS', { setNewBlock: block })
        index += 1
      }

      data.forEach(item => {
        this.loadImage(item.id)
        const imageBlock = createBlock('imageBlock')
        imageBlock.properties = {
          ...imageBlock.properties,
          damMediaId: item.id.toString(),
          damMediaUuid: item.imageUuid.toString()
        }
        addBlock(imageBlock)
        if (this.pageBreaks) {
          addBlock(createBlock('pageBreakBlock'))
        }
      })
      this.$store.commit('nmhEditorStore/SET_BLOCKS', {
        deleteSingleBlockProgrammatically: this.index
      })
    },
    addPageBreaks (pageBreaks) {
      this.pageBreaks = pageBreaks
    },
    deleteImage () {
      this.$store.commit('nmhEditorStore/SET_BLOCK_PROPERTIES', {
        index: this.index,
        property: 'damMediaId',
        data: ''
      })
    },
    async loadImage (damMediaId) {
      this.loading = true
      try {
        const coreApiAsset = await CoreApi().get('/media/' + damMediaId)
        const damApiImage = await DamApi().get(`/image/${coreApiAsset.data.damMediaEmbed.filename}/800/600`)
        this.media.push(coreApiAsset.data)
        this.imageData.url = damApiImage.data.base64Image
        this.imageData.imageCaption = coreApiAsset.data.imageCaption
        this.imageData.imageAttribution = coreApiAsset.data.imageAttribution
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    hideEditModal () {
      this.editModal = false
    },
    hideSearhModal () {
      this.searchModal = false
    },
    hideUrlModal () {
      this.urlModal = false
    },
    hideUploadModal () {
      this.uploadModal = false
    },
    mapMedia (asset) {
      let imageCaption = asset.metadata.description
      if (imageCaption === '') {
        imageCaption = asset.metadata.caption
      }
      if (imageCaption === '') {
        imageCaption = asset.metadata.headline
      }
      return {
        damMediaEmbed: {
          damId: asset.id,
          filename: asset.basename
        },
        imageCaption: imageCaption,
        imageAttribution: asset.metadata.byLine,
        hideCaption: false,
        exportWoodWing: false,
        size: asset.size,
        dimensions: {
          width: asset.dimensions.width,
          height: asset.dimensions.height
        }
      }
    },
    setMedia (media) {
      this.media = []
      media.forEach(asset => {
        this.media.push(this.mapMedia(asset))
      })
    },
    showEditModalWithMedia (media) {
      this.setMedia(media)
      this.searchModal = false
      this.editModal = true
    },
    showEditModal () {
      this.editModal = true
    },
    showSearchModal () {
      this.searchModal = true
    },
    showUploadModal (files) {
      this.filesFromUrl = files
      this.uploadModal = true
      this.urlModal = false
    },
    showUrlModal () {
      this.urlModal = true
    }
  },
  mounted () {
    if (this.imageHasId) {
      try {
        this.loadImage(this.properties.damMediaId)
      } catch (e) {
        console.error(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .image-block {
    &__content-wrapper {
      @include relative(_);
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      @include bp(0 14) {
        margin: auto;
        max-width: 400px;
      }
      @include bp(14) {
        grid-template-columns: 300px auto;
      }
    }
    &__no-content-wrapper {
      @include grid-gap(10px);
      display: grid;
      justify-content: center;
      @include bp(7) {
        grid-template-columns: repeat(3, max-content);
      }
    }
    &__inner-wr {
      position: relative;
    }
    &__img {
      @include radius(6px);
    }
    &__title {
      @include font(400 14px 20px "Roboto");
      margin: 0;
      color: #8a96ac;
      font-style: italic;
    }
    &__source {
      @include font(500 14px "Roboto");
      @include margin(8px 0 0 0);
      font-style: italic;
      color: #7e8695;
    }
    &__delete-photo {
      @include absolute(0 0 _ _, 1);
      background: #fc4b6c;
      &:hover {
        background: darken(#fc4b6c, 10%);
      }
    }
  }
</style>

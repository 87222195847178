<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="!dataLoaded"></app-preloader-full>
      <div>
        <div class="m-b-10">
          <div class="row">
            <slot name="actions">
              <div class="col-lg-6">
                <div class="pull-left">
                  <app-article-transitions-buttons
                    v-if="hasShowTransitionsButtons"
                    :update-draft="updateDraft"
                  >
                  </app-article-transitions-buttons>
                  <router-link
                    tag="a"
                    :title="$t('buttons.edit')"
                    class="btn btn-success"
                    :class="{ 'btn-disabled': activity }"
                    :to="{ name: 'article_edit', params: { id: article.id }}"
                    v-if="article.setting.status === 'draft'"
                    data-test="article_list_btn_edit"
                  >
                    <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
                  </router-link>
                  <app-button-copy-to-clipboard
                    data-test="article_btn_copy_to_clipboard"
                    label="ID"
                    :data="article.documentId"
                    :notify-message="$t('notify.id_was_copied')"
                  >
                  </app-button-copy-to-clipboard>
                  <button
                    v-if="!vlmDotyk"
                    :disabled="disableWoodWingExportButton"
                    type="button"
                    class="btn btn-warning"
                    :class="{disabled: disableWoodWingExportButton}"
                    @click="toggleShowWoodWingExportModal"
                  >
                    {{ $t("woodWing.export.open") }}
                  </button>
                  <ArticleTransferToExportButton :article="article" />
                  <app-wood-wing-export-modal
                    v-if="showWoodWingExportModal"
                    :siteId="article.site"
                    @close="toggleShowWoodWingExportModal"
                    @export="exportWoodWing"
                  ></app-wood-wing-export-modal>
                  <GenerateHtmlFromPrArticle v-if="showHtmlPrGenerationBtn" :article="article" />
                </div>
              </div>
              <div class="col-lg-3 text-right">
                <span>{{ $t('article.status') }}:
                  <app-article-status :article="article" class="m-r-20"></app-article-status>
                  <br>
                  <app-article-last-change-info :article="article"></app-article-last-change-info>
                  <app-article-version-info v-if="dataLoaded" :selectedArticle="article"></app-article-version-info>
                </span>
              </div>
              <div class="col-lg-3">
                <div class="article-view__right-buttons m-r-5">
                  <BtnCreateCampaign :article="article" />
                  <BtnArticlePreview
                    :article="article"
                    :disable-on-expired="true"
                    data-test="article_btn_preview"
                    :save-before-preview="false"
                  />
                  <app-button-close
                    route-name="article_list"
                    data-test="article_btn_close"
                  >
                  </app-button-close>
                </div>
              </div>
            </slot>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card card-body">
              <ArticleActivityInfo
                v-if="dataLoaded"
                :article="article"
              />
              <h3 class="article-edit-header">{{ $t("article.main_content") }}</h3>
              <table class="table table-striped table-bordered" style="table-layout:fixed;">
                <tbody>
                <tr>
                  <th width="280">{{ $t("article.type") }}</th>
                  <td><span class="label label-inverse">{{ articleType }}</span></td>
                </tr>
                <tr>
                  <th>{{ $t("article.document_id") }}</th>
                  <td>{{ article.documentId }}</td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.hidden") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.hidden"></i>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.first_published_date") }}</th>
                  <td>{{ article.firstPublishedDate | prettyDateTime }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.title") }}</th>
                  <td>{{ article.field.title }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.sub_title") }}</th>
                  <td>{{ article.field.subTitle }} {{ seoTitleSuffix }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.short_title") }}</th>
                  <td>{{ article.field.shortTitle }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.first_paragraph") }}</th>
                  <td>{{ article.field.firstParagraph }}</td>
                </tr>
                <tr v-if="!vlmDotyk">
                  <th>{{ $t("article.listing_teaser") }}</th>
                  <td>{{ article.field.listingTeaser }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.body_text") }}</th>
                  <td>
                    <div class="card card-body article-view-blocks">
                      <NmhEditor v-if="hasShowBodyText && dataLoaded" />
                    </div>
                  </td>
                </tr>
                <tr v-if="article.type === 'external'">
                  <th>{{ $t("article.external_url") }}</th>
                  <td>{{ article.external.url }}</td>
                </tr>
                <tr v-if="article.type === 'external'">
                  <th>{{ $t("article.external_author") }}</th>
                  <td>{{ article.external.author }}</td>
                </tr>
                <tr v-if="article.type === 'external'">
                  <th>{{ $t("article.external_site_name") }}</th>
                  <td>{{ article.external.siteName }}</td>
                </tr>
                <tr v-if="article.type === 'external'">
                  <th>{{ $t("article.external_site_url") }}</th>
                  <td>{{ article.external.siteUrl }}</td>
                </tr>
                <tr v-if="article.type === 'external'">
                  <th>{{ $t("article.external_rubric_name") }}</th>
                  <td>{{ article.external.rubricName }}</td>
                </tr>
                <tr v-if="article.type === 'external'">
                  <th>{{ $t("article.external_rubric_url") }}</th>
                  <td>{{ article.external.rubricUrl }}</td>
                </tr>
                <tr>
                  <th width="300">
                    {{ $t("article.hero_image") }}
                  </th>
                  <td>
                    <app-media
                      v-if="article.expanded.heroImage"
                      :media="article.expanded.heroImage"
                      :width="480"
                      :height="270"
                      show-media-info
                    >
                    </app-media>
                  </td>
                </tr>
                <tr>
                  <th width="300">
                    {{ $t("article.listing_image") }}
                  </th>
                  <td>
                    <app-media
                      v-if="article.expanded.listingImage"
                      :media="article.expanded.listingImage"
                      :width="480"
                      :height="270"
                      show-media-info
                    >
                    </app-media>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.gallery") }}</th>
                  <td>
                    <div v-for="(image, index) in article.expanded.gallery" :key="`item-${index}`">
                      <app-media
                        :media="image"
                        :width="480"
                        :height="270"
                        show-media-info
                      >
                      </app-media>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.video_type") }}</th>
                  <td>
                    {{ article.field.videoType }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.video") }}</th>
                  <td>
                    {{ article.field.video }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.categories") }}</th>
                  <td>
                    <span
                      v-for="(category, index) in article.expanded.categories"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ category.title }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.related_articles") }}</th>
                  <td>
                    <app-related-article-list :show-remove-button="false"></app-related-article-list>
                  </td>
                </tr>
                <tr v-if="article.expanded.site && article.expanded.site.name === 'zdravie'">
                  <th>{{ $t("article.related_disease_articles") }}</th>
                  <td>
                    <app-related-disease-article-list :show-remove-button="false"></app-related-disease-article-list>
                  </td>
                </tr>
                <tr v-if="article.quiz">
                  <th>{{ $t("article.quiz") }}</th>
                  <td>
                    {{ article.quiz }}
                  </td>
                </tr>
                <tr v-if="article.recipe">
                  <th>{{ $t("article.recipe") }}</th>
                  <td>
                    <router-link
                      tag="a"
                      class="btn btn-success"
                      :to="{ name: 'recipe_detail', params: { id: article.recipe }}"
                      data-test="article_list_btn_recipe_detail"
                    >
                      {{ article.recipe }} - {{ article.expanded.recipe.title }}
                    </router-link>
                  </td>
                </tr>
                <tr v-if="article.articleTest">
                  <th>{{ $t("article.test") }}</th>
                  <td>
                    {{ article.articleTest.id }}
                  </td>
                </tr>
                <tr v-if="article.printPublication && article.expanded.printPublication.printTitleInfo">
                  <th>{{ $t("article.print_publication") }}</th>
                  <td>
                    {{ article.expanded.printPublication.printTitleInfo.title }}&nbsp;
                    {{ article.expanded.printPublication.title }}&nbsp;
                    {{ article.field.printCategory }}
                  </td>
                </tr>
                <tr v-if="article.field.pageTitles.length > 0">
                  <th>{{ $t("article.page_titles") }}</th>
                  <td>
                    <ul>
                      <li v-for="(pageTitle, index) in article.field.pageTitles" :key="`item-${index}`">
                        {{ pageTitle }}
                      </li>
                    </ul>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="card card-body" v-if="article.abTestVariants.length && article.setting.abTested === false">
              <h3 class="article-edit-header">{{ $t("article.ab_test.title") }}</h3>
              <div
                v-for="abTestItem in article.abTestVariants" :key="abTestItem.variant"
                class="ab-test" :class="{winner: abTestItem.winner}"
              >
                <h4 class="article-edit-header">
                  {{ $t("article.ab_test.variant") }}
                  <span class="text-uppercase">{{ abTestItem.variant }}</span>
                  <span v-if="abTestItem.winner"> - {{ $t('article.ab_test.winner') }}</span>
                </h4>
                <table class="table table-striped table-bordered">
                  <tbody>
                  <tr>
                    <th width="280">{{ $t('article.title') }}</th>
                    <td>{{ abTestItem.title }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('article.hero_image') }}</th>
                    <td>
                      <app-media
                        v-if="abTestItem.image"
                        :media="abTestItem.image"
                        :width="480"
                        :height="270"
                        show-media-info
                      >
                      </app-media>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('article.ab_test.ctr') }}</th>
                    <td>{{ abTestItem.conversionRate | formatNumber }}%</td>
                  </tr>
                  <tr>
                    <th>{{ $t('article.ab_test.impressions_count') }}</th>
                    <td>{{ abTestItem.impressions }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('article.ab_test.clicks_count') }}</th>
                    <td>{{ abTestItem.clicks }}</td>
                  </tr>
                  <tr>
                    <th>{{ $t('article.ab_test.duration') }}</th>
                    <td>{{ abTestItem.startTime | prettyDateTime }} - {{ abTestItem.finishTime | prettyDateTime }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-body">
              <h3 class="article-edit-header">{{ $t("article.main_settings") }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr v-if="article.type !== 'external'">
                  <th colspan="2">
                    {{ $t("article.urls") }}
                    <app-article-url-info
                      v-if="article.id"
                      :article="article"
                      hide-copy-to-clipboard-button
                      hide-refresh-url-button
                      hide-labels
                    >
                    </app-article-url-info>
                  </th>
                  <td v-if="false">

                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.site") }}</th>
                  <td>
                    <template v-if="article.expanded.site">{{ article.expanded.site.title }}</template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.main_rubric") }}</th>
                  <td>
                    <template v-if="article.expanded.mainRubric">{{ article.expanded.mainRubric.title }}</template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.other_rubrics") }}</th>
                  <td>
                    <span
                      v-for="(rubric, index) in article.rubrics"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ rubric.title }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.order_date") }}</th>
                  <td>{{ article.orderDate | prettyDateTime }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.published_since") }}</th>
                  <td>{{ article.publishedSince | prettyDateTime }}</td>
                </tr>
                <tr>
                  <th>{{ $t("article.published_until") }}</th>
                  <td>{{ article.publishedUntil | prettyDateTime }}</td>
                </tr>
                <tr v-if="vlm">
                  <th>{{ $t("article.hiddenOnHp") }}</th>
                  <td><i class="fa fa-check" v-if="article.flag.hiddenOnHp" /></td>
                </tr>
                <tr>
                  <th>{{ $t("article.authors") }}</th>
                  <td>
                    <span
                      v-for="(author, index) in article.expanded.authors"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ author.name }}
                    </span>
                  </td>
                </tr>
                <tr v-if="article.expanded.site && article.expanded.site.name === 'zdravie'">
                  <th>{{ $t("article.doctors") }}</th>
                  <td>
                    <span
                      v-for="(doctor, index) in article.expanded.doctors"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ doctor.name }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.main_author") }}</th>
                  <td>
                                        <span
                                          v-if="article.expanded.mainAuthor"
                                          class="label label-info m-r-5"
                                        >{{ article.expanded.mainAuthor.name }}</span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.safety_topics") }}</th>
                  <td>
                    <span
                      v-for="(safetyTopic, index) in article.expanded.safetyTopics"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ safetyTopic.title }}<br>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.owners") }}</th>
                  <td>
                    <span
                      v-for="(owner, index) in article.expanded.owners"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ getUserInfo(owner) }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.sharedUsers") }}</th>
                  <td>
                    <span
                      v-for="(sharedUser, index) in article.expanded.sharedUsers"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ getUserInfo(sharedUser) }}
                    </span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card card-body">
              <h3 class="article-edit-header">{{ $t("article.meta_info") }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <th width="300">{{ $t("article.layout") }}</th>
                  <td>
                    {{ articleLayout }}
                  </td>
                </tr>
                <tr v-if="!vlmDotyk">
                  <th>{{ $t("article.tags") }}</th>
                  <td>
                    <span
                      v-for="(tag, index) in article.expanded.tags"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ tag.title }}<br>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.selected_topics") }}</th>
                  <td>
                    <span
                      v-for="(selectedTopic, index) in article.expanded.selectedTopics"
                      :key="`item-${index}`"
                      class="label label-info m-r-5"
                    >
                      {{ selectedTopic.title }}<br>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t("article.flags") }}</th>
                  <td>
                    <app-flags :flag="article.flag"></app-flags>
                  </td>
                </tr>
                <tr v-if="article.flag.updated">
                  <th>{{ $t("article.updated_until_datetime") }}</th>
                  <td>
                    {{ article.flag.updatedUntilDatetime | prettyDateTime }}
                  </td>
                </tr>
                <tr v-if="article.flag.justNow">
                  <th>{{ $t("article.just_now_until_datetime") }}</th>
                  <td>
                    {{ article.flag.justNowUntilDatetime | prettyDateTime }}
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.disable_comments") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.disableComments"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.editorial_article") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.type_source_editorial_article"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.native") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.flag.native"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.pr") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.prArticle"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.source_type_internal_author") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.internalAuthor"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.source_type_agency_article") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.agencyArticle"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.copied_from_print") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.copiedFromPrint"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.source_type_external_author") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.externalAuthor"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.source_type_transfered_article") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.transferedArticle"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.source_type_ai_assisted") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.aiAssisted"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.paid_content") }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.paidContent"></i>
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.proofread_status") }}</th>
                  <td>
                    {{ article.setting.proofreadStatus }}
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t('article.ab_test.title') }}</th>
                  <td>
                    <i class="fa fa-check" v-if="article.setting.abTested"></i>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card card-body">
              <h3 class="article-edit-header">{{ $t("article.system_settings") }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <th width="300">{{ $t("article.migrated_system") }}</th>
                  <td>
                    {{ article.setting.migratedSystem }}
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.migrated_id") }}</th>
                  <td>
                    {{ article.setting.migratedId }}
                  </td>
                </tr>
                <tr>
                  <th width="300">{{ $t("article.migrated_remote_id") }}</th>
                  <td>
                    {{ article.setting.migratedRemoteId }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="card card-body">
              <slot name="history">
                <h3 class="article-edit-header">{{ $t("article.article_history") }}</h3>
                <app-article-history v-if="dataLoaded" :article="article"></app-article-history>
              </slot>
            </div>
            <!-- temporary hidden - see CMS-3740 and CMS-2592-->
            <div v-if="false" class="card card-body">
              <h3 class="article-edit-header">{{ $t('article.antares_info') }}</h3>
              <app-article-antares-info v-if="dataLoaded" :article="article"></app-article-antares-info>
            </div>
          </div>
        </div>
        <ArticleActivitiesChecker />
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '@/components/ModuleView'
import ArticleModel from '@/model/ArticleModel.js'
import Flags from '@/components/article/ArticleFlags'
import ArticleTransitionsButtons from '@/components/article/ArticleTransitionButtons'
import BtnArticlePreview from '@/components/buttons/BtnArticlePreview'
import BtnCreateCampaign from '@/components/buttons/BtnCreateCampaign'
import BtnCopyToClipboard from '@/components/buttons/BtnCopyToClipboard'
import ButtonClose from '@/components/shared/ButtonClose'
import ArticleStatus from '@/components/article/ArticleStatus'
import ArticleService from '@/services/article/ArticleService'
import Media from '@/components/shared/Media'
import ArticleLastChangeInfo from '@/components/article/ArticleLastChangeInfo'
import NotifyService from '@/services/NotifyService'
import ArticleHistory from '@/components/article/ArticleHistory'
import ArticleAntaresInfo from '@/components/article/ArticleAntaresInfo'
import ArticleVersionInfo from '@/components/article/ArticleVersionsInfo'
import ArticleActivityInfo from '@/components/article/ArticleActivityInfo'
import RelatedArticleList from '@/components/article/ArticleRelatedList'
import RelatedDiseaseArticleList from '@/components/article/ArticleRelatedDiseaseList'
import PreloaderFull from '@/components/preloader/PreloaderFull'
import MediaMixin from '@/components/mixins/Media'
import UserMixin from '@/components/mixins/User'
import ArticleUrlInfo from '@/components/article/ArticleUrlInfo'
import ArticleTransferToExportButton from '@/components/article/ArticleTransferToExportButton'
import { ROLE_SUPER_ADMIN } from '@/model/ValueObject/UserRole'
import ArticleLayoutMixin from '@/components/mixins/valueObject/ArticleLayoutMixin'
import ArticleTypeMixin, { ARTICLE_TYPE_ARTICLE } from '@/components/mixins/valueObject/ArticleTypeMixin'
import { dataHubAbnApi } from '@/api/datahub'
import Vue from 'vue'
import WoodWingExportModal from '@/components/article/ArticleWoodWingExportModal'
import GenerateHtmlFromPrArticle from '@/components/vlm/GenerateHtmlFromPrArticle'
import { mapGetters } from 'vuex'
import NmhEditor from '@/components/nmhEditor/NmhEditor'
import ArticleValidationService from '@/services/article/ArticleValidationService'
import ArticleActivitiesChecker from '@/components/article/ArticleActivitiesChecker'

export default {
  name: 'ArticleView',
  mixins: [MediaMixin, UserMixin, ArticleLayoutMixin, ArticleTypeMixin],
  components: {
    ArticleActivityInfo,
    ArticleActivitiesChecker,
    GenerateHtmlFromPrArticle,
    ArticleTransferToExportButton,
    appModuleView: ModuleView,
    appFlags: Flags,
    appArticleTransitionsButtons: ArticleTransitionsButtons,
    appArticleStatus: ArticleStatus,
    BtnArticlePreview,
    BtnCreateCampaign,
    appButtonCopyToClipboard: BtnCopyToClipboard,
    appButtonClose: ButtonClose,
    appMedia: Media,
    appArticleLastChangeInfo: ArticleLastChangeInfo,
    appArticleHistory: ArticleHistory,
    appArticleAntaresInfo: ArticleAntaresInfo,
    appArticleVersionInfo: ArticleVersionInfo,
    appPreloaderFull: PreloaderFull,
    appArticleUrlInfo: ArticleUrlInfo,
    appRelatedArticleList: RelatedArticleList,
    appRelatedDiseaseArticleList: RelatedDiseaseArticleList,
    appWoodWingExportModal: WoodWingExportModal,
    NmhEditor
  },
  data () {
    return {
      dataLoaded: false,
      article: ArticleModel,
      error: null,
      showWoodWingExportModal: false,
      disableWoodWingExportButton: true
    }
  },
  computed: {
    ...mapGetters(['vlm', 'vlmDotyk']),
    showHtmlPrGenerationBtn () {
      const articlePublishedOrReadyToBePublished = ['ready', 'published'].includes(this.article.setting.status)
      return this.vlm && this.article.setting.prArticle && articlePublishedOrReadyToBePublished
    },
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    activity () {
      const activity = this.$store.getters['activity/articleActivityById'](this.article.documentId)
      return !!activity
    },
    hasShowBodyText () {
      return ['external', 'quiz', 'recipe', 'video'].indexOf(this.article.type) < 0
    },
    hasShowTransitionsButtons () {
      if (this.article.setting.status === 'draft') {
        return false
      }
      if (this.activity === false) {
        return true
      }
      return this.article.setting.status === 'published'
    },
    articleLayout () {
      return this.articleLayoutValues.find(layout => {
        if (layout.id === this.article.setting.layout) {
          return layout
        }
      }).title
    },
    articleType () {
      return this.articleTypeValues.find(type => {
        if (type.id === this.article.type) {
          return type
        }
      }).title
    },
    seoTitleSuffix () {
      if (this.article.expanded.site && this.article.expanded.site.name === 'trend') {
        return ArticleValidationService.trendSeoTitleSuffix
      }
      return ''
    }
  },
  methods: {
    async getArticle () {
      this.dataLoaded = false
      await this.$store.dispatch('article/fetchFullArticle', this.$route.params.id)
        .then(async () => {
          this.article = this.$store.getters['article/fullArticle']
          this.checkWoodWingExport(this.article)
          const blocks = await ArticleService.getBodyBlocks(this.article)
          this.$store.commit('nmhEditorStore/SET_BLOCKS', {
            init: {
              blocks,
              disabled: true
            }
          })
          this.dataLoaded = true
          if (this.article.abTestVariants.length && this.article.setting.abTested === false) {
            dataHubAbnApi().get('/result?documentId=' + this.article.documentId).then((res) => {
              const winner = res.data.variants.sort(
                (a, b) => b.conversionRate - a.conversionRate || a.name.localeCompare(b.name)
              )[0]
              this.article.abTestVariants.forEach((abTestItem) => {
                const resultItem = res.data.variants.find(
                  (variantItem) => variantItem.name.toLowerCase() === abTestItem.variant
                )
                Vue.set(abTestItem, 'impressions', resultItem.impressions)
                Vue.set(abTestItem, 'clicks', resultItem.clicks)
                Vue.set(abTestItem, 'conversionRate', resultItem.conversionRate * 100)
                Vue.set(abTestItem, 'startTime', res.data.startTime)
                Vue.set(abTestItem, 'finishTime', res.data.finishTime)
                Vue.set(abTestItem, 'winner', winner === resultItem)
              })
            })
          }
        })
        .catch(error => {
          this.dataLoaded = true
          console.error(error)
        })
    },
    deleteArticle () {
      this.$store.dispatch('article/deleteRecord', this.article)
        .then(() => {
          if (this.$store.getters['article/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/article')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['article/error'])
          }
        })
        .catch(error => console.log(error))
    },
    async updateDraft () {

    },
    isSuperAdmin () {
      return this.$store.getters['user/hasRole'](ROLE_SUPER_ADMIN)
    },
    toggleShowWoodWingExportModal () {
      this.showWoodWingExportModal = !this.showWoodWingExportModal
    },
    async exportWoodWing ({ issue, category }) {
      try {
        await this.updateDraft()
        await this.$store.dispatch('woodWing/export', {
          article: this.article.id,
          issue,
          category
        })
        this.toggleShowWoodWingExportModal()
        NotifyService.setSuccessMessage(this.$t('woodWing.export.success'))
        this.disableWoodWingExportButton = true
      } catch (e) {
        NotifyService.setErrorMessage(this.$t('woodWing.export.error'))
      }
    },
    async checkWoodWingExport (article) {
      if (article.type === ARTICLE_TYPE_ARTICLE && article.documentId) {
        try {
          await this.$store.dispatch('woodWing/existsWWExport', article.documentId)
          this.disableWoodWingExportButton = true
        } catch (e) {
          this.disableWoodWingExportButton = false
        }
      }
    }
  },
  mounted () {
    this.getArticle()
  }
}
</script>

<style lang="scss">
  .article-view__right-buttons {
    justify-content: flex-end;
    display: flex;
    gap: 1%;
  }
  .btn-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  .article-edit-header {
    color: #bbb;
    font-weight: 100;
  }
  .ab-test {
    position: relative;
    &.winner:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      background: rgba(65, 184, 131, .15);
      z-index: 1;
      pointer-events: none;
      top: 30px;
      bottom: 16px;
    }
  }
  .article-view-blocks {
    box-shadow: unset !important;
  }
</style>

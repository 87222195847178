<template>
  <section class="abstract-filter">
    <form class="abstract-filter__form" @submit.prevent="onFilter">
      <div :class="`abstract-filter__form-panel-1 ${filterPanelClass}`">
        <slot />
        <slot name="advancedFilter" v-if="showAdvancedFilter && advancedFilter" />
      </div>
      <div
        class="abstract-filter__form-panel-2"
        :style="getButtonsGridColumns()">
        <slot name="searchButtons">
          <ButtonSearch v-if="showSearchButton"/>
          <ButtonShowAdvancedFilter
            v-if="showAdvancedFilter"
            :on-click="toggleAdvancedFilter"
            :is-enabled="advancedFilter"
          />
        </slot>
        <div class="abstract-filter__separator"></div>
        <slot name="resetButtons">
          <ButtonResetFilter :on-click="onFilterReset" />
          <ButtonCreate
            v-if="showCreateButton"
            :route-name="createNewRouteName"
            :label="createNewLabel"
            :link-target="createInNewTab ? '_blank' : '_self'"
          />
          <slot name="afterResetButtons" />
        </slot>
      </div>
    </form>
  </section>
</template>

<script>

import ButtonCreate from '@/components/shared/ButtonCreate'
import ButtonSearch from '@/components/buttons/ButtonSearch'
import ButtonResetFilter from '@/components/buttons/ButtonResetFilter'
import ButtonShowAdvancedFilter from '@/components/buttons/BtnArticleShowAdvancedFilter'

export default {
  name: 'AbstractFilter',
  props: {
    onFilter: {
      type: Function,
      required: true
    },
    onFilterReset: {
      type: Function,
      required: true
    },
    filterPanelClass: {
      type: String,
      required: true
    },
    showCreateButton: {
      type: Boolean,
      default: true
    },
    createNewRouteName: {
      type: String,
      default: ''
    },
    createInNewTab: {
      type: Boolean,
      default: false
    },
    createNewLabel: {
      type: String,
      default: ''
    },
    showSearchButton: {
      type: Boolean,
      default: true
    },
    showAdvancedFilter: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ButtonShowAdvancedFilter,
    ButtonCreate,
    ButtonSearch,
    ButtonResetFilter
  },
  data () {
    return {
      advancedFilter: false
    }
  },
  methods: {
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    },
    getButtonsGridColumns () {
      const count = 3 + (this.showAdvancedFilter ? 1 : 0) + (this.showCreateButton ? 1 : 0)
      return {
        '--buttons-grid-columns': 'max-content '.repeat(count)
      }
    }
  }
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.abstract-filter {
  display: grid;
  grid-gap: rem(20px);
  margin-bottom: 1rem;
  &__form {
    display: grid;
    grid-gap: rem(20px);
    @include bp(0 7) {
      margin-top: rem(15px);
    }
    @include bp(11) {
      grid-template-columns: auto max-content;
    }
  }
  &__form-panel-1 {
    display: grid;
    grid-gap: rem(10px);
    align-items: flex-end;
    @include bp(7) {
      grid-gap: rem(20px);
    }
  }
  &__form-panel-2 {
    margin-top: rem(25px);
    display: grid;
    grid-template-columns: var(--buttons-grid-columns);
    grid-gap: rem(10px);
    @include bp(0 11) {
      margin-top: 0;
      order: -1;
    }
  }
  &__separator {
    margin: 0 rem(10px);
    background: #D1DBE4;
    width: 1px;
    height: rem(40px);
  }
  .form-group {
    margin-bottom: 0 !important;
  }
}
</style>
